import Vue from "vue";
import { Component } from "vue-property-decorator";
import { WaitKeys } from "@/helpers/WaitKeys";
import { Navbar, Sidebar } from "@/components";

@Component({
    name: "layout",
    metaInfo: {
        titleTemplate: "%s | Workday Tower Management",
        meta: [
            { name: "description", property: "og:description", content:  "Pernod Ricard's Workday Tower Management" },
            { name: "title", property: "og:title", content: "Workday Tower Management" },
            { name: "image", property: "og:image", content: `https://${process.env.VUE_APP_WEB_DOMAIN}/logo.png` },
            { property: "og:image:type", content: "image/png" },
            { property: "og:image:width", content: "50" },
            { property: "og:image:height", content: "48" },
            { name: "twitter:card", content: "summary_large_image" },
            { property: "twitter:domain", content: process.env.VUE_APP_WEB_DOMAIN },
            { property: "twitter:url", content:`https://${process.env.VUE_APP_WEB_DOMAIN}` },
            { name: "twitter:title", content: "Workday Tower Management" },
            { name: "twitter:description", content:  "Pernod Ricard's Workday Tower Management" },
            { name: "twitter:image", content: `https://${process.env.VUE_APP_WEB_DOMAIN}/logo.png` },
        ]
    },
    components: {
        Navbar,
        Sidebar,
    },
})
export default class Layout extends Vue {
    get isAuthenticated(): boolean {
        return this.$store.getters["account/isAuthenticated"];
    }

    async created(): Promise<void> {
        this.$wait.start(WaitKeys.baseData);

        let userLoaded = true;
        try {
            await this.$store.dispatch("account/loadUser");
        }
        catch (error) {
            userLoaded = false;
        }

        if (userLoaded) {
            await this.$store.dispatch("account/loadUserGraph");
            await this.$store.dispatch("account/loadUserPhoto");
        }
        else {
            await this.$router.replace({ name: "unauthorized" });
        }

        this.$wait.end(WaitKeys.baseData);
    }

    onSuccessNotification(messages: string | string[]): void {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }

        messages.forEach(message => {
            this.$notify({ type: "success", duration: 5000, text: message });
        });
    }

    onErrorNotification(messages: string | string[]): void {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }

        messages.forEach(message => {
            this.$notify({ type: "error", duration: 5000, text: message });
        });
    }
}
